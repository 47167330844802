import { enumCssColors } from "@/utils/color";

// This ordering is purposeful, please do not change
export enum CropType {
  SOYBEAN = "SOYBEAN",
  CORN = "CORN",
  WHEAT = "WHEAT",
  BEAN = "BEAN",
  COTTON = "COTTON",
  SUGARCANE = "SUGARCANE",
  RICE = "RICE",
  SORGHUM = "SORGHUM",
  UNKNOWN = "UNKNOWN",
  PEANUT = "PEANUT",
  EUCALYPTUS = "EUCALYPTUS",
  CASSAVA = "CASSAVA",
  OAT = "OAT",
  COFFEE = "COFFEE",
  OTHERS = "OTHERS",
  BARLEY = "BARLEY",
  BRACHIARIA = "BRACHIARIA",
  ONION = "ONION",
  PEA = "PEA",
  POTATO = "POTATO",
  RYE = "RYE",
  SUNFLOWER = "SUNFLOWER",
  SWEET_POTATO = "SWEET_POTATO",
  TOBACCO = "TOBACCO",
  TOMATO = "TOMATO",
  TRITICALE = "TRITICALE",
  NOT_IDENTIFIED = "NOT_IDENTIFIED",
}

export enum CropTypeTranslate {
  BARLEY = "Cevada",
  BEAN = "Feijão",
  BRACHIARIA = "Braquiaria",
  CASSAVA = "Mandioca",
  COFFEE = "Café",
  CORN = "Milho",
  COTTON = "Algodão",
  EUCALYPTUS = "Eucalipto",
  NOT_IDENTIFIED = "Não apontada",
  OAT = "Aveia",
  ONION = "Cebola",
  OTHERS = "Outros",
  PEA = "Ervilha",
  PEANUT = "Amendoim",
  POTATO = "Batata",
  RICE = "Arroz",
  RYE = "Centeio",
  SORGHUM = "Sorgo",
  SOYBEAN = "Soja",
  SUGARCANE = "Cana-de-açúcar",
  SUNFLOWER = "Girassol",
  SWEET_POTATO = "Batata Doce",
  TOBACCO = "Tabaco",
  TOMATO = "Tomate",
  TRITICALE = "Triticale",
  UNKNOWN = "Desconhecida",
  WHEAT = "Trigo",
}

export enum CropColors {
  BARLEY = "#FFE5AD",
  BEAN = "#92775A",
  BRACHIARIA = "#ACE71C",
  CASSAVA = "#754d2a",
  COFFEE = "#7C0010",
  CORN = "#F2C33B",
  COTTON = "#CEC2B6",
  EUCALYPTUS = "#5D9600",
  NOT_IDENTIFIED = "#B6B6B6",
  OAT = "#EE9C15",
  ONION = "#D97533",
  OTHERS = "#4D4F5C",
  PEA = "#587514",
  PEANUT = "#B72D0D",
  POTATO = "#E6CD8E",
  RICE = "#A8BA89",
  RYE = "#563728",
  SORGHUM = "#E56E4A",
  SOYBEAN = "#ECCC9C",
  SUGARCANE = "#CBA65F",
  SUNFLOWER = "#F6CF0B",
  SWEET_POTATO = "#AE374A",
  TOBACCO = "#3D250D",
  TOMATO = "#EA2F02",
  TRITICALE = "#8C984F",
  UNKNOWN = "#B6B6B6",
  WHEAT = "#F8C368",
}

export enum CropStatus {
  DIVERGENT = "DIVERGENT",
  FINANCED = "FINANCED",
  NOT_IDENTIFIED = "NOT_IDENTIFIED",
}

export enum CropStatusColor {
  DIVERGENT = "#9CE9E9",
  FINANCED = "#B9E99C",
  NOT_IDENTIFIED = "#FF7D7D",
}

export const CSS_STYLE_CROP_COLORS: string = enumCssColors(
  CropType,
  CropColors,
  "crop",
);
export const CSS_STYLE_CROP_STATUS_COLORS: string = enumCssColors(
  CropStatus,
  CropStatusColor,
  "crop-status",
);
